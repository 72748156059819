import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../../../styles/components/global/CtaHomeSearch.scss';
import '../../../styles/productsLandingPages/home/CtaHomeSearch.scss';
import Section from '../../global/Section';
import LazyNearbyListings from '../../property/components/LazyNearbyListings';
import SearchBar from '../../property/components/SearchBar';
import SearchHubsCarousel from '../../searchHubsCarousel';
import tracking from '../../services/tracking_service';

const CtaHomeSearch = ({
  deviceType,
  currentLocation,
  mapboxToken,
  homeSearchUrl,
  propertyFromAddressUrl,
  searchHubs,
  searchHubTrackingInfo,
  searchBarTrackingInfo
}) => {
  const ctaDescription = {
    firstLine: 'HomeLight can help you find your next home.',
    secondLine: 'Search below to discover the latest homes for sale in your area.'
  };

  const trackEvent = ({category, action, label}) => {
    tracking.trackEvent(category, action, label);
  };

  const trackCarouselClick = (propertyUuid) => {
    tracking.trackEvent('Home page', 'click', 'nearby_listings', propertyUuid, true);
  };

  return (
    <Section background="white" className="cta-home-search-content">
      <div className="cta-home-search-content-title">Find a place you’ll love to live</div>
      <div className="cta-home-search-content-description">
        {deviceType === 'desktop' || deviceType === 'tablet' ? (
            <>
              <div>{ctaDescription.firstLine}</div>
              <div>{ctaDescription.secondLine}</div>
            </>
          ) :
          (
            <div>{`${ctaDescription.firstLine} ${ctaDescription.secondLine}`}</div>
          )
        }
      </div>
      <SearchBar
        deviceType={deviceType}
        mapboxToken={mapboxToken}
        homeSearchUrl={homeSearchUrl}
        propertyFromAddressUrl={propertyFromAddressUrl}
        showTextButton={deviceType !== 'mobile'}
        mobileSearchButton={deviceType === 'mobile'}
        showSearchIcon
        trackEvent={ trackEvent }
        trackingInfo={ searchBarTrackingInfo }
      />
      <LazyNearbyListings
        latitude={currentLocation?.latitude}
        longitude={currentLocation?.longitude}
        stateOrProvince={currentLocation?.state?.code}
        deviceType={deviceType}
        trackLinkClick={trackCarouselClick}
        trackEvent={() => {}}
      />
      <SearchHubsCarousel
        title='Browse homes for sale'
        searchHubs={searchHubs}
        trackEvent={ trackEvent }
        trackingInfo={ searchHubTrackingInfo }
      />
    </ Section>
  )
};

CtaHomeSearch.propTypes = {
  deviceType: PropTypes.string,
  homeSearchUrl: PropTypes.string,
  mapboxToken: PropTypes.string,
  propertyFromAddressUrl: PropTypes.string,
  searchHubs: PropTypes.arrayOf(PropTypes.object),
  searchHubTrackingInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string
  }),
  searchBarTrackingInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string
  })
}

CtaHomeSearch.defaultProps = {
  deviceType: null,
  homeSearchUrl: null,
  mapboxToken: null,
  propertyFromAddressUrl: null,
  searchHubs: null,
  searchHubTrackingInfo: {},
  searchBarTrackingInfo: {}
}

export default CtaHomeSearch;
