import {server} from './server';

export const autocompleteApiRequest = (geography) => {
  const apiUrl = `/place-autocomplete?value=${geography}&area_types=place,postcode,address&` +
                 'fields=county_or_parish,state_or_province,hl_full_address,city,postal_code,uuid,display_address';
  let data = [];
  let error = false;

  const makeRequest = async () => {
    try {
      data = await server.get(apiUrl);
    } catch (err) {
      data = [];
      error = true;
    } finally {
      return { data, error };
    }
  };

  return makeRequest().then(({data, error}) => ({data, error}));
};
