const contentType = 'application/json';

export const server = {

  get (url) {
    return this.fetch(url, 'GET');
  },

  put (url, data) {
    return this.fetch(url, 'PUT', data);
  },

  post (url, data) {
    return this.fetch(url, 'POST', data);
  },

  delete (url) {
    return this.fetch(url, 'DELETE');
  },

  fetch (url, type, data) {
    if (type === 'POST') {
      data = data || {};
      const tokenElem = document.querySelector('[name=csrf-token]');
      data.authenticity_token = (tokenElem ? tokenElem.getAttribute('content') : undefined);
    }
    if (typeof data === typeof {}) {
      data = JSON.stringify(data);
    }

    return fetch(url, {
      method: type,
      headers: {
        'Content-Type': contentType
      },
      body: data
    }).then(response => response.json());
  }

};
