import storage_service from "../components/services/storage_service";

const { cookies } = storage_service;

export const abUserExperiments = () => {
  return JSON.parse(cookies.get('split') || '{}')
}

export const abUserActiveExperiments = () => {
  // the split cookies contains all the experiments that the abUser has been part of including those that are already
  // finished. This code will filter out finished experiments which have experimentValue equal to true so that we only
  // keep active experiments
  return Object.fromEntries(Object.entries(abUserExperiments()).filter(([_, experimentValue]) =>
    typeof experimentValue !== "boolean"
  ))
}
