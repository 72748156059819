import React, { useState, useEffect, useRef, useMemo }  from 'react';
import PropTypes from "prop-types";
import SearchIcon from '../../icons/SearchIcon'
import Autocomplete from './Autocomplete'
import InputDecorator from './InputDecorator'
import { debounce } from '../../../utils/debounce'
import { autocompleteRequest } from '../../../utils/autocompleteRequest'
import { autocompleteApiRequest } from '../../../utils/autocompleteApiRequest'
import { stateCodeOf } from '../../../utils/stateUtils'
import { server } from '../../../utils/server'
import '../../../styles/property/_search_bar.scss';

const SearchBar = ({
  deviceType,
  mapboxToken,
  homeSearchUrl,
  propertyFromAddressUrl,
  showTextButton,
  mobileSearchButton,
  trackEvent,
  showSearchIcon = false,
  trackingInfo,
  useAutocompleteApi,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [showClearButton, setShowClearButton] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null)
  const inputRef = useRef(null)
  const placeHolderText = 'Enter a city, ZIP code or address'

  let mobile = true;
  if (deviceType) {
    mobile = deviceType === 'mobile'
  } else if (typeof window !== 'undefined') {
    mobile = window.innerWidth < 767;
  }

  useEffect(() => {
    performSearch()
    if (trackEvent) {
      trackEvent('Property Search', 'Search', 'submits_search')
    }
  }, [selectedOption])

  const clearInput = () => {
    setInputValue("")
    setShowClearButton(false)
    setOptions([])
    setFocusedOptionIndex(null)
  }

  const updateOptionFocus = (increment) => {
    if (!options.length) { return }
    if (focusedOptionIndex === null || Number.isNaN(focusedOptionIndex)) {
      setFocusedOptionIndex(0)
      return
    }

    const lastIndex = options.length - 1
    const newIndex = (focusedOptionIndex + increment) % options.length
    setFocusedOptionIndex(newIndex < 0 ? lastIndex : newIndex)
  }

  const handleSelectWithKey = () => {
    if (!options.length) { return }
    const targetIndex = focusedOptionIndex || 0
    handleSuggestionSelect(options[targetIndex])
    clearInput()
  }

  const handleInputChange = async (geography) => {
    try {
      if (geography.length > 0) {
        setShowClearButton(true)
      }

      if (geography.length < 3) {
        return null
      }

      const {data, error} = useAutocompleteApi ? await autocompleteApiRequest(geography) : await autocompleteRequest(geography, mapboxToken);

      if (error) {
        throw error
      }

      setOptions(data)
    } catch(error) {
      // TODO: handle mapbox API errors
      setOptions([])
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce((geography) => handleInputChange(geography), 500), [])

  const updateInputValue = (ev) => {
    const geography = ev.target.value
    setInputValue(geography)
    debouncedChangeHandler(geography)
  }

  const handleSuggestionSelect = (suggestion) => {
    setOptions([])
    setSelectedOption(suggestion)
  }

  const performSearch = async () => {
    trackEvent(trackingInfo);
    if (selectedOption === null) {
      return null
    }

    const homeSearchUrlWithSlug = `${homeSearchUrl}/homes-for-sale?filter[slug]=`

    if (useAutocompleteApi) {
      const {slug, url} = selectedOption;
      if (url) {
        window.location.assign(url);
      } else {
        // send user to home search city page if we're unable to find the address
        window.location.assign(`${homeSearchUrlWithSlug}${slug}`);
      }
    } else {
      const {
        context,
        text,
        place_name: placeName,
        place_type: placeType
      } = selectedOption

      const isAddress = placeType[0] === 'address'
      const isZipCode = placeType[0] === 'postcode'

      if (isAddress) {
        const response = await getPropertyUrlFromAddress(placeName)

        if (response.error || !response.data.url) {
          // send user to home search city page if we're unable to find the address
          const city = context.find((el) => (el.id.includes('place'))).text
          const state = context.find((el) => (el.id.includes('region'))).text
          const slug = getSlug(`${city}, ${state}`)
          window.location.assign(`${homeSearchUrlWithSlug}${slug}`)
        } else {
          window.location.assign(response.data.url)
        }

        return null
      } else if (isZipCode) {
        const postcode = text
        window.location.assign(`${homeSearchUrlWithSlug}${postcode}`)
      } else {
        const slug = getSlug(placeName)
        window.location.assign(`${homeSearchUrlWithSlug}${slug}`)
      }
    }
  }

  const getPropertyUrlFromAddress = (placeName) => {
    const url = `${propertyFromAddressUrl}?hl_full_address=${encodeURIComponent(placeName)}`
    let data = [];
    let error = false

    const makeRequest = async (placeName) => {
      try {
        const response = await server.get(url);
        data = await response;
      } catch (err) {
        data = [];
        error = true;
      } finally {
        return { data, error };
      }
    };

    return makeRequest().then((url) => (url));
  }

  const getSlug = (placeName) => {
    let city
    let state
    [city, state] = placeName.split(', ')
    return `${city.toLowerCase()}-${stateCodeOf(state).toLowerCase()}`.replaceAll(' ', '-').replaceAll("'", '-').replace(/st\./g, 'saint')
  }

  const handleKey = (e) => {
    switch (e.key) {
      case 'Enter':
        handleSelectWithKey()
        break
      case 'ArrowDown':
        updateOptionFocus(1)
        break
      case 'ArrowUp':
        updateOptionFocus(-1)
        break
      case 'Escape':
        clearInput()
        break
    }
  }

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget

    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setOptions([])
      }
    }, 0)
  }

  const handleSearchButtonClick = (ev) => {
    const targetIndex = focusedOptionIndex || 0

    if (options[0]) {
      handleSuggestionSelect(options[targetIndex])
      clearInput()
    }
  }
  return (
    <div className="search-bar">
      <form
        className="search-bar-form"
        onKeyPress={(ev) => ev.key === 'Enter' ? ev.preventDefault() : null}
        onBlur={handleBlur}
      >
        { (mobile || showSearchIcon) && <SearchIcon /> }
        <input
          className="search-bar-input"
          title={placeHolderText}
          placeholder={placeHolderText}
          type='text'
          ref={inputRef}
          value={inputValue}
          onChange={updateInputValue}
          onKeyDown={handleKey}
        />
        <InputDecorator
          mobile={mobile}
          showClearButton={showClearButton}
          onClearClick={clearInput}
          onSearchClick={handleSearchButtonClick}
          showTextButton={showTextButton}
        />
        <Autocomplete
          options={options}
          onListItemSelect={handleSuggestionSelect}
          focusedOptionIndex={focusedOptionIndex}
          useAutocompleteApi={useAutocompleteApi}
        />
        { mobileSearchButton && (
          <button
            className="mobile-search-button"
            onClick={ev=>{handleSearchButtonClick(ev)}}
          >
            Search homes
          </button>
        ) }
      </form>
    </div>
  )
}

SearchBar.propTypes = {
  deviceType: PropTypes.string,
  mapboxToken: PropTypes.string,
  homeSearchUrl: PropTypes.string,
  propertyFromAddressUrl: PropTypes.string,
  showTextButton: PropTypes.bool,
  mobileSearchButton: PropTypes.bool,
  trackEvent: PropTypes.func,
  showSearchIcon: PropTypes.bool,
  trackingInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string
  }),
  useAutocompleteApi: PropTypes.bool,
}

SearchBar.defaultProps = {
  showTextButton: false,
  mobileSearchButton: false,
  showSearchIcon: false,
  trackEvent: () => {},
  trackingInfo: {},
  useAutocompleteApi: false,
}

export default SearchBar
