let cookiesHelper = {
  /**
   * @method useCookies
   * @returns {boolean}
   */
  useObjectStore () {
    try {

      let date    = new Date(new Date().getTime() + (1000)).toGMTString();
      let cookie  = 'chef=hl2016; expires=' + date + "; path=/";

      document.cookie = cookie;

      if (document.cookie.indexOf(cookie) === -1) {
        throw new Error("Cookies Not Supported");
      }

    } catch (e) {
      let message = "It seems like you have disabled cookies. Our site needs cookies to function properly.";
      alert(message);
    }
  },

  useCookies () {

    let testString = "";
    let i = 0;

    for (i; i < 150; i++) {
      testString += "a";
    }

    try {
      if (typeof localStorage === 'object' && typeof sessionStorage === 'object') {

        localStorage.setItem('localStorage', testString);
        let returnLocalString = localStorage.removeItem('localStorage');

        sessionStorage.setItem('sessionStorage', testString);
        let returnSessionString = sessionStorage.removeItem('sessionStorage');

        if (returnLocalString === testString && returnSessionString === testString) {
          return false;
        }
      }
    } catch (e) {}

    return true;
  },

  parse (key) {

    let cookies = {};
    let cookie_string = document.cookie;
    let cookie_pieces = cookie_string.split(";");
    let homelight_cookies = cookie_pieces.filter(piece => !piece.match(/__/));
    let i = 0;
    let len = (homelight_cookies && homelight_cookies.length) || 0;

    for (i; i < len; i +=1) {
      let cookie  = homelight_cookies[i];
      let k       = cookie.split(/\=(.+)/)[0];
      let value   = cookie.split(/\=(.+)/)[1];
      if ( k && value ) {
        k     = k.trim();
        value = value.trim();
        cookies[k] = unescape(value);
      }
    }

    if (!key) {
      return cookies;
    } else {
      return cookies[key];
    }

  },

  clear (key) {
    document.cookie = key + "=''; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
    return true;
  },

  set (key, value) {
    if ( value === undefined ) {
      return false;
    }
    let date = new Date(new Date().getTime() + (1000*60*60*24*7)).toGMTString();
    document.cookie = key + "=" + escape(value) + "; expires=" + date + "; path=/";
    return true;
  },

  // alias for parse
  get (key) {
    return this.parse(key);
  },

  // Alias for set, but chainable
  put (key, value) {
    this.set(key, value);
    return this;
  }
};


/**
 * @class Storage
 * @param storage
 * @constructor
 */
function Storage (storage) {

  this.storage = storage;


  this.getJSON = function (key) {
    try {
      return JSON.parse(this.parse(key))
    } catch (er) {
      return {}
    }
  };

  /**
   * @method parse
   * @param key
   * @returns {*}
   */
  this.parse = function (key) {
    if (cookiesHelper.useCookies()){
      return cookiesHelper.parse(key);
    } else {
      if ( !key ){
        return this.storage;
      } else {
        return unescape(this.storage.getItem(key));
      }
    }
  };

  /**
   * @method clear
   * @param key
   * @returns {*}
   */
  this.clear = function (key) {
    if ( cookiesHelper.useCookies()){
      return cookiesHelper.clear(key);
    } else {
      return this.storage.removeItem(key);
    }
  };

  /**
   * @method set
   * @param key
   * @param value
   * @returns {*}
   */
  this.set = function (key, value) {
    if (cookiesHelper.useCookies()) {
      return cookiesHelper.set(key, value);
    } else {
      return this.storage.setItem(key, escape(value));
    }
  };

  /**
   * Alias for set
   * @method put
   * @param key
   * @param value
   * @chainable
   * @returns {Storage}
   */
  this.put = function(key, value) {
    this.set(key, value);
    return this;
  };

  /**
   * @method get
   * @param key
   * @returns {*}
   */
  // alias for parse
  this.get = function(key){
    return this.parse(key);
  };

  return this;
}

let storageHelper;
let sessionHelper;

/**
 * ### Implements the Storage interface for local storage
 * @method HomeLight.Storage
 * @for Storage
 * @type {Storage}
 */
try {
  storageHelper = new Storage(localStorage);
} catch (e) {
  storageHelper = new Storage({});
}


/**
 * ### Implements the Storage interface for session storage
 * @method HomeLight.Session
 * @for Storage
 * @type {Storage}
 */
try {
  sessionHelper = new Storage(sessionStorage);
} catch (e) {
  sessionHelper = new Storage({});
}

// Bulletproofing - in case anyone plucks a key off, or inadvertently breaks scope.
Object.keys(cookiesHelper).forEach(key => {
  if (cookiesHelper.hasOwnProperty(key) && typeof cookiesHelper[key] === 'function' ) {
    cookiesHelper[key] = cookiesHelper[key].bind(cookiesHelper);
  }
});
Object.keys(storageHelper).forEach(key => {
  if (storageHelper.hasOwnProperty(key) && typeof storageHelper[key] === 'function' ) {
    storageHelper[key] = storageHelper[key].bind(storageHelper);
  }
});
Object.keys(sessionHelper).forEach(key => {
  if (sessionHelper.hasOwnProperty(key) && typeof sessionHelper[key] === 'function' ) {
    sessionHelper[key] = sessionHelper[key].bind(sessionHelper);
  }
});

export const cookies = cookiesHelper;
export const storage = storageHelper;
export const session = sessionHelper;
export default {
  cookies,
  storage,
  session
}
