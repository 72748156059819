import {useState, useEffect, useCallback} from 'react'

const useLoadOnVisibility = (targetRef, callback) => {
  const [loaded, setLoaded] = useState(false);

  const triggerLoad = useCallback(() => {
    if (!targetRef || !targetRef.current) return

    const clientRects = targetRef.current.getBoundingClientRect()

    if (!loaded && clientRects.y < window.innerHeight + 20) {
      callback();
      setLoaded(true);
    }
  }, [targetRef, callback])

  useEffect(() => {
    // To mount component in case it's in the viewport from the start
    triggerLoad();
    if (!loaded) {
      window.addEventListener('scroll', triggerLoad);
    }
    return () => {
      if (!loaded) {
        window.removeEventListener('scroll', triggerLoad);
      }
    }
  }, [loaded])

  return { loaded }
}

export default useLoadOnVisibility
