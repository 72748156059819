import * as Sentry from '@sentry/react';

const dsn = process.env.SENTRY_FRONTEND_DSN;

export const initializeSentry = () => {
  const hostname = globalThis.location?.hostname;
  if (!hostname) {
    return;
  }

  let environment = '';
  if (/sandbox|demo|staging/.test(hostname)) {
    environment = 'staging';
  } else if (/release/.test(hostname)) {
    environment = 'release';
  } else if (/local|dev/.test(hostname)) {
    environment = 'development';
  } else if (/homelight/.test(hostname)) {
    environment = 'production';
  }

  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    environment: environment
  });

  if (window) window.Sentry ||= Sentry
};
