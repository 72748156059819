import React  from 'react';
import ClearIcon from '../../icons/ClearIcon'
import SearchIconButton from './SearchIconButton'

const InputDecorator = ({
  mobile,
  showClearButton,
  onClearClick,
  onSearchClick,
  showTextButton
}) => {
  return (
    <div className="decorator-wrapper">
      {showClearButton && (
        <button
          id="clear-icon"
          className="clear-icon-button"
          style={{'padding': `${mobile ? '0' : '0 16px 0 0'}`}}
          onClick={ev=>{ev.preventDefault(); onClearClick()}}
          onKeyPress={(ev) =>
            ev.key === "Enter" && onClearClick ? onClearClick() : null
          }
        >
          <ClearIcon />
        </button>
      )}

      {!mobile && (
        <SearchIconButton 
          onSearchClick={ev=>{ev.preventDefault(); onSearchClick(ev)}}
          showTextButton={ showTextButton }
        />
      )}
    </div>
  );
}

export default InputDecorator