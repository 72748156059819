import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import ChevronIcon from "../../components/icons/ChevronIcon";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import "../../styles/components/global/BaseCarousel.scss";
import tracking from '../../components/services/tracking_service'

const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
  <span {...props}>{children}</span>
);

const BaseCarousel = ({
  dots,
  slidesToScroll,
  slidesToShow,
  arrows,
  mobileArrows,
  infinite,
  autoplay,
  children,
  centerPadding,
  centerMode,
  draggable,
  swipe,
  trackingInfo,
  breakpoints
}) => {
  const carouselWrapper = useRef(null);

  const defaultResponsiveSettings = [{
    breakpoint: 1024,
    settings: {
      dots: dots && children.length > 1,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: mobileArrows
    }
  }];

  const settings = {
    dots: dots && children.length > slidesToShow,
    infinite: infinite,
    arrows: arrows,
    slidesToScroll: slidesToScroll,
    slidesToShow: slidesToShow,
    focusOnSelect: false,
    autoplay: autoplay,
    initialSlide: 0,
    draggable,
    swipe,
    responsive: breakpoints.length > 0 ? breakpoints : defaultResponsiveSettings,
    afterChange: (newIndex) => {
      const slides = carouselWrapper.current.querySelectorAll('.slick-slide');
      slides.forEach(slide => {
        slide.classList.remove('previous-slide');
        slide.classList.remove('next-slide');
      });

      const previousSlide = carouselWrapper.current.querySelector(`[data-index="${(newIndex - 1) % slides.length}"]`)
      if (previousSlide) {
        previousSlide.classList.add('previous-slide');
      }
      const nextSlide = carouselWrapper.current.querySelector(`[data-index="${(newIndex + 1) % slides.length}"]`)
      if (nextSlide) {
        nextSlide.classList.add('next-slide');
      }

      if (!!trackingInfo) {
        tracking.trackEvent(trackingInfo.category, trackingInfo.action, trackingInfo.label);
      }
    }
  };

  if (breakpoints.length > 0) {
    settings.responsive.forEach(breakpt => {
      breakpt.settings['dots'] = dots && children.length > 1;
      breakpt.settings['arrows'] = breakpt.settings['arrows'] && arrows;
    });
  }

  if (arrows) {
    //https://github.com/akiran/react-slick/issues/1195
    settings.prevArrow = <SlickButtonFix><ChevronIcon style={{'fill': '#273653'}}/></SlickButtonFix>
    settings.nextArrow = <SlickButtonFix><ChevronIcon style={{'fill': '#273653'}}/></SlickButtonFix>
  }

  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (isMobile) {
    settings.centerMode = centerMode;
    settings.centerPadding = centerPadding;
  }

  return (
    <div className="base-carousel" ref={carouselWrapper}>
      <Slider {...settings}>
        { children }
      </Slider>
    </div>
  );
};

BaseCarousel.propTypes = {
  dots: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  slidesToShow: PropTypes.number,
  arrows: PropTypes.bool,
  mobileArrows: PropTypes.bool,
  infinite: PropTypes.bool,
  autoplay: PropTypes.bool,
  centerPadding: PropTypes.string,
  centerMode: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.objectOf(PropTypes.node)]),
  draggable: PropTypes.bool,
  swipe: PropTypes.bool,
  trackingInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    sectionSelector: PropTypes.string
  }),
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape(
      {
        breakpoint: PropTypes.number,
        settings: PropTypes.shape({ slidesToScroll: PropTypes.number, slidesToShow: PropTypes.number })
      }
    )
  )
};

BaseCarousel.defaultProps = {
  dots: false,
  slidesToScroll: 3,
  slidesToShow: 3,
  arrows: false,
  infinite: true,
  autoplay: false,
  centerPadding: '30px',
  centerMode: true,
  children: [],
  mobileArrows: false,
  draggable: true,
  swipe: true,
  trackingInfo: {},
  breakpoints: []
};

export default BaseCarousel;
