import React from 'react';
import PropTypes from 'prop-types';
import ImageNotFoundIcon from '../../icons/ImageNotFoundIcon';

const PropertyImage = ({ url, alt, onImageFail, lazyload, lockedPhotos }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [failedToLoad, setFailedToLoad] = React.useState(false);
  const blurredImageClass = lockedPhotos ? 'blurred-image' : '';
  let lazyloadAttributes;

  if (lazyload) {
    lazyloadAttributes = {
      "data-src": url,
      className: "lazyload property-image",
      loading: "lazy"
    }
  } else {
    lazyloadAttributes = {
      src: url,
      className: `property-image ${blurredImageClass}`
    }
  }

  const onImageLoad = () => {
    setLoaded(true);
    if (failedToLoad) {
      setFailedToLoad(false);
    }
  };

  const onFail = () => {
    onImageFail(url);
    setFailedToLoad(true);
  }

  return (
    <React.Fragment>
      {url && url.length ?
        <React.Fragment>
          <img
            {...lazyloadAttributes}
            alt={loaded ? alt : ''}
            style={failedToLoad ? {visibility: 'hidden', position: 'absolute'} : {}}
            onError={onFail}
            onLoad={onImageLoad}
          />
          {failedToLoad && (
            <div className='empty-image no-images-placeholder'><ImageNotFoundIcon /></div>
          )}
        </React.Fragment> :
        <div className='empty-image no-images-placeholder'><ImageNotFoundIcon /></div>
      }
    </React.Fragment>
  );
};

PropertyImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onImageFail: PropTypes.func,
  lazyload: PropTypes.bool,
  lockedPhotos: PropTypes.bool
};

PropertyImage.defaultProps = {
  alt: '',
  onImageFail: ()=>{},
  lazyload: true,
  lockedPhotos: false
};

export default PropertyImage;
