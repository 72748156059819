import React from 'react';
import PropTypes from 'prop-types';
import ChevronIcon from '../icons/ChevronIcon';
import '../../styles/components/global/Accordion.scss';
import tracking from '../services/tracking_service';

class Accordion extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      expanded: props.defaultExpanded || false
    };

    this.toggleExpansion = this.toggleExpansion.bind(this);
    this.setDetailsRef = this.setDetailsRef.bind(this);
    this.setTitleRef = this.setTitleRef.bind(this);
  }

  componentDidMount () {
    // Update once to read title height after mount - async to allow CSS transition to fire
    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  }

  toggleExpansion () {
    const expandedUpdated = !this.state.expanded
    this.setState({ expanded: expandedUpdated });

    const {trackingCategory, trackingAction, trackingLabel} = this.props;
    if ( !trackingCategory || !trackingAction || !trackingLabel ) return;

    const key = expandedUpdated ? "expanded" : "collapsed"
    tracking.trackEvent(
      typeof trackingCategory === 'object' ?  trackingCategory[key] : trackingCategory,
      typeof trackingAction === 'object' ?  trackingAction[key] : trackingAction,
      typeof trackingLabel === 'object' ?  trackingLabel[key] : trackingLabel,
    );
  }

  setDetailsRef (element) {
    this.detailsPanel = element;
  }

  setTitleRef (element) {
    this.title = element;
  }

  render () {
    const { title, details } = this.props;
    const { expanded } = this.state;

    const parentClasses = `accordion ${expanded ? 'expanded' : null}`
    const detailsClasses = `accordion-details details-${expanded ? 'expanded' : 'hidden'}`;
    const detailsHeight = this.detailsPanel ? this.detailsPanel.getBoundingClientRect().height : 0;
    const accordionHeight = this.title ? this.title.getBoundingClientRect().height : null;
    const wrapperHeight = expanded ? `${accordionHeight + detailsHeight}px` : `${accordionHeight}px`;
    const wrapperStyle = accordionHeight ? { height: wrapperHeight } : {};

    return (
      <div className={parentClasses} style={ wrapperStyle }>
        <div className='accordion-top' onClick={ this.toggleExpansion } ref={ this.setTitleRef }>
          { title }
          <div className='expand-icon'>
            <ChevronIcon direction={ expanded ? 'down' : 'right' } style={{ width: '12px', height: '12px' }} />
          </div>
        </div>

        <div className={ detailsClasses } ref={ this.setDetailsRef }>
          { details }
        </div>
      </div>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.oneOfType(
    [
      PropTypes.element,
      PropTypes.string
    ]
  ),
  details: PropTypes.oneOfType(
    [
      PropTypes.element,
      PropTypes.string
    ]
  ),
  defaultExpanded: PropTypes.bool,
  trackingCategory: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ expanded: PropTypes.string, collapsed: PropTypes.string })
  ]),
  trackingAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ expanded: PropTypes.string, collapsed: PropTypes.string })
  ]),
  trackingLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ expanded: PropTypes.string, collapsed: PropTypes.string })
  ])
};

Accordion.defaultProps = {
  trackingCategory: '',
  trackingAction: '',
  trackingLabel: ''
}

export default Accordion
