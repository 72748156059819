import React from 'react';
import PropTypes from 'prop-types';
import BaseCarousel from '../global/BaseCarousel';
import SearchHubCardWithImage from "../global/SearchHubCardWithImage";

class SearchHubsCarousel extends React.Component {
  renderSearchHubs() {
    const { searchHubs, trackEvent, trackingInfo } = this.props;
    return (
      searchHubs.map(searchHub => {
        return (
          <div key={ searchHub.link }>
            <SearchHubCardWithImage
              areaSlug={ searchHub.area_slug }
              imageUrl={ searchHub.image_url }
              cityName={ searchHub.name }
              searchHubPath={ searchHub.link }
              trackEvent={ trackEvent }
              trackingInfo={ trackingInfo }
            />
          </div>
        )
      })
    )
  }

  render() {
    const { title, searchHubs  } = this.props;
    if (!searchHubs || searchHubs.length === 0) {
      return null;
    }

    const breakPoints = [
      {
        breakpoint: 1023,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 587,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          arrows: false
        }
      }
    ];

    return (
      <div
        id="carousel-component"
        className="search-hub-carousel-section carousel-component"
      >
        <h4 className="search-hub-content subtitle">
          {title}
        </h4>

        <BaseCarousel
          arrows
          draggable
          slidesToShow={3}
          slidesToScroll={3}
          centerPadding='5px'
          breakpoints={breakPoints}
        >
          {this.renderSearchHubs()}
        </BaseCarousel>

      </div>
    )
  }
}

SearchHubsCarousel.propTypes = {
  searchHubs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string,
  trackEvent: PropTypes.func,
  trackingInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string
  })
}

SearchHubsCarousel.defaultProps = {
  title: "",
  trackEvent: () => {},
  trackingInfo: {}
}

export default SearchHubsCarousel;
