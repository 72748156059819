import React from 'react';
import DescriptionTag from './DescriptionTag';

const MapDescriptionTag = ({tagText}) => (
  <DescriptionTag
    classes='non-interactive'
    tagText={tagText}
  />
)

export default MapDescriptionTag;
