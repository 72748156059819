import React, { useState, useRef, useCallback } from 'react';
import loadable from '@loadable/component';
import useLoadOnVisibility from '../../../hooks/useLoadOnVisibility';

const LazyNearbyListings = (props) => {
  const targetEl = useRef(null);
  const [Element, setElement] = useState(null);

  const mountList = useCallback(() => {
    const Element = loadable(() => import('../../loadable/property/NearbyListings'));
    setElement(Element);
  }, []);

  const { loaded } = useLoadOnVisibility(targetEl, mountList);

  return (
    <div ref={targetEl}>
      {loaded && <Element {...props} />}
    </div>
  )
}

export default LazyNearbyListings;
