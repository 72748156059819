import { server } from './server'

export const autocompleteRequest = (geography, mapboxToken) => {
  const mapboxUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places";
  // TODO: where to store these tokens
  const authToken = mapboxToken;
  // Removing neighborhood until it is supported
  // const types = 'region,district,place,postcode,address,neighborhood';
  const types = "place,postcode,address";
  const url = `${mapboxUrl}/${encodeURIComponent(
    geography
  )}.json?autocomplete=true&access_token=${encodeURIComponent(
    authToken
  )}&country=us&types=${encodeURIComponent(types)}`;
  let data = [];
  let error = false;

  const makeRequest = async () => {
    try {
      const response = await server.get(url);
      data = await response.features;
    } catch (err) {
      data = [];
      error = true;
    } finally {
      return { data, error };
    }
  };

  return makeRequest().then(({ data, error }) => ({ data, error }));
};
