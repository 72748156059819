import React from 'react';
import MapMarkerIcon from '../../icons/MapMarkerIcon'

const stateValues = (ctx) => {
  return ctx && ctx.filter(function(x) {
    return x.id.indexOf('region.') > -1;
  })[0];
}

const zipValue = (ctx) => {
  let val = ctx && ctx.filter(function(x) {
    return x.id.indexOf('postcode.') > -1;
  })[0];
  return val && val.text
}

const normalizeOption = (result) => {
  if (!result) { return ''}
  let context = result.context,
      place_name = result.place_name || '',
      text = result.text,
      place_type = result.place_type;
  let isAddress = place_type && place_type[0] === 'address';
  let isCity = place_type && place_type[0] === 'place';
  let isNeighborhood = place_type && place_type[0] === 'neighborhood';
  let isZip = place_type && place_type[0] === 'postcode';
  let state = stateValues(context);
  let postal_code = isZip ? text : zipValue(context);
  let neighborhood = place_name.replace(', United States', '');
  let fullAddress = place_name && place_name.replace(', United States', '');
  let description;
  let formattedState = state && [state.short_code && state.short_code.replace('US-', ''), state.text].filter(function(x) {
      return x;
  })[0];

  let cityDescription = "".concat(text, ", ").concat(formattedState)
  let addressParts = fullAddress.split(',');
  addressParts[addressParts.length - 1] = ` ${formattedState}`;
  let addressDescription = addressParts.join(',')

  const descriptionMap = {
    place: cityDescription,
    address: addressDescription,
    postcode: postal_code,
    neighborhood: neighborhood,
  }

  return descriptionMap[place_type[0]]
}

 const Autocomplete = ({ options, onListItemSelect, focusedOptionIndex, useAutocompleteApi }) => {
  if (!options || options.length === 0) return null;

  const onOptionKeyDown = (e, option) => {
    if (e.key === 'Enter') {
      onListItemSelect(option);
    }
  }
  const getListItemFromOption = (option) => (useAutocompleteApi ? option.description : normalizeOption(option));
  const getListItemKeyFromOption = (option) => (useAutocompleteApi ? option.description : option.place_name);

  return (
    <div id="autocomplete-wrapper">
      <ul className="list">
        {options.map((option, ix) => (
          // TODO: Change the HTML structure to handle tab navigation by default, instead of setting a tabindex on this parent `div`
          <div
            className="list-item-wrapper"
            id={`autocomplete-option-${ix}`}
            tabIndex={0}
            focused={ix === focusedOptionIndex}
            key={`${getListItemKeyFromOption(option)}-list-item`}
            onClick={() => {
              onListItemSelect(option);
            }}
            onKeyDown={e => onOptionKeyDown(e, option)}
          >
            <div className="map-icon-wrapper">
              <MapMarkerIcon />
            </div>
            <li className="list-item">{getListItemFromOption(option)}</li>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default Autocomplete
