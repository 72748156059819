import React from "react";
import PropTypes from "prop-types";

import "../../styles/components/global/Section.scss";

const Section = ({ children, background, className, id }) => (
  <div id={id} className={`section-area section-area--${background}`}>
    <div className={`section-area__container ${className}`}>
      { children }
    </div>
  </div>
);

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  background: PropTypes.string,
  className: PropTypes.string
};

Section.defaultProps = {
  children: [],
  background: "",
  className: ""
};

export default Section;
