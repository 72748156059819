import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './Accordion'
import '../../styles/components/global/Faq.scss'

class Faq extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      faq: JSON.parse(props.faq) || [],
      defaultExpanded: props.default === 'expanded'
    };
  }

  addParagraphTagOnLineBreak(answer) {
    return typeof answer === 'string' ? (
      <React.Fragment>
        {
          answer.split("\n").map((item, i) => {
            return <p key={ i } dangerouslySetInnerHTML={{ __html: item }}/>
          })
        }
      </React.Fragment>
    ) : answer;
  }

  formatAnswer(answer) {
    if (this.props.shouldAddParagraphTagOnLineBreak) {
      return this.addParagraphTagOnLineBreak(answer);
    } else {
      return (<React.Fragment>
        {
          <div dangerouslySetInnerHTML={{ __html: answer }}/>
        }
      </React.Fragment>)
    }
  }

  render() {
    const { faq, defaultExpanded } = this.state;
    const { addTopBorder, trackingCategory, trackingAction, trackingLabel } = this.props;

    const questions = faq.map( (item, i) => {
      return (
        <Accordion
          key={ i }
          title={ <div dangerouslySetInnerHTML={{ __html: item.question}} /> }
          details={ this.formatAnswer(item.answer) }
          defaultExpanded={ defaultExpanded }
          trackingCategory={trackingCategory}
          trackingAction={trackingAction}
          trackingLabel={trackingLabel}
        />
      );
    });

    return (
      <div className="faq-component">
        <h2 className="faq-title">{ this.props.title }</h2>
        <div className="faq" style={addTopBorder ? { borderTop: '1px solid #ddd' } : {}}>
          { questions }
        </div>
      </div>
    );
  }
}

Faq.propTypes = {
  title: PropTypes.string,
  addTopBorder: PropTypes.bool,
  shouldAddParagraphTagOnLineBreak: PropTypes.bool,
  trackingCategory: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ expanded: PropTypes.string, collapsed: PropTypes.string })
  ]),
  trackingAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ expanded: PropTypes.string, collapsed: PropTypes.string })
  ]),
  trackingLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ expanded: PropTypes.string, collapsed: PropTypes.string })
  ])
};

Faq.defaultProps = {
  title: 'Frequently Asked Questions',
  addTopBorder: true,
  shouldAddParagraphTagOnLineBreak: true,
  trackingCategory: null,
  trackingAction: null,
  trackingLabel: null
};

export default Faq;
