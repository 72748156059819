import React from 'react';
import PropTypes from 'prop-types';
import PropertyImage from "../property/components/PropertyImage";
import ImageNotFoundIcon from "../icons/ImageNotFoundIcon";
import MapDescriptionTag from "../property/components/MapDescriptionTag";


const SearchHubCardWithImage = ({ areaSlug,
                                  imageUrl,
                                  cityName,
                                  searchHubPath,
                                  trackEvent,
                                  trackingInfo
                                }) => {
  const handleTrackEvent = () => {
    if (trackEvent && trackingInfo) {
      const label = `${trackingInfo.label}_${areaSlug}`;
      const trackingData = {
        category: trackingInfo.category,
        action: trackingInfo.action,
        label: label
      };
      trackEvent(trackingData);
    }
  };

  return (
    <div
      className='carousel card-wrapper'
    >
      {imageUrl ? (
        <PropertyImage
          key={imageUrl}
          url={imageUrl}
          alt={`Homes for sale in ${cityName}`}
        />
      ) : (
        <div className='empty-image'><ImageNotFoundIcon/></div>
      )}
      <a
        href={searchHubPath}
        className='search-hub-carousel-card'
        onClick={ () => handleTrackEvent()}
      >
        <div className='card-text'>
          <p className='city-name'>
            {cityName}
          </p>
        </div>
        <span className='tag-wrapper search-hub-tag'>
          <MapDescriptionTag
            tagText='Search homes for sale'
          />
        </span>
      </a>
    </div>
  )
}

SearchHubCardWithImage.propTypes = {
  areaSlug: PropTypes.string,
  imageUrl: PropTypes.string,
  cityName: PropTypes.string,
  searchHubPath: PropTypes.string,
  trackEvent: PropTypes.func,
  trackingInfo: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string
  })
};

SearchHubCardWithImage.defaultProps = {
  trackEvent: () => {},
  trackingInfo: {}
};

export default SearchHubCardWithImage;
