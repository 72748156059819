import React from "react";
import SearchIcon from '../../icons/SearchIcon'
import '../../../styles/property/_search_icon_button.scss';

const SearchIconButton = ({
  onSearchClick,
  showTextButton
}) => {
  return (
    <button
      id="search-icon"
      className="search-icon-button"
      style={{'padding': '0 '}}
      onClick={ev=>{ev.preventDefault(); onSearchClick(ev)}}
      onKeyPress={(ev) => ev.key === "Enter" && onSearchClick ? onSearchClick(ev) : null}
    >
      <div className="icon-wrapper">
        { showTextButton ? "Search homes" : <SearchIcon fill="#FFFFFF" /> }
      </div>
    </button>
  )
}

export default SearchIconButton