import React from 'react';
import '../../../styles/property/_description_tag.scss';

const DescriptionTag = ({ classes, tagText }) => (
  <span className={`description-tag ${classes}`}>
    <span className='description-tag-text'>
      {tagText}
    </span>
  </span>
)

export default DescriptionTag;
