import React from 'react';
import PropTypes from 'prop-types';
import BaseCarousel from '../../../components/global/BaseCarousel';

import '../../../styles/productsLandingPages/home/TestimonialsCarousel.scss';

const TestimonialsCarousel = ({ testimonials }) => {
  const breakPoints = [
    {
      breakpoint: 1365,
      settings: {
        slidesToScroll: 2,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1,
        arrows: false
      }
    }
  ];

  return (
    <BaseCarousel dots slidesToScroll={3} slidesToShow={3} arrows breakpoints={breakPoints}>
      {
        testimonials.map((testimonial) => (
          <div key={`testimonial-${testimonial.name}`}>
            <div className='slide testimonial-card'>
              <img className='img lazyload' data-src={testimonial.src} />
              <div className='name'>{testimonial.name}</div>
              <p className='from'>{testimonial.place}</p>
              <hr className='break'/>
              <div className='quotation'>{testimonial.quote}</div>
              <div className='link' />
            </div>
          </div>
        ))
      }
    </BaseCarousel>
  );
};

TestimonialsCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape(
      {
        testimonial: PropTypes.string,
        name: PropTypes.string,
        place: PropTypes.string,
        quote: PropTypes.string
      }
    )
  )
};

export default TestimonialsCarousel;
